function resetTabIndex() {
    $('a, input, select, button, textarea').each(function () {
        let attr = $(this).attr('tabindex');
        if (typeof attr !== typeof undefined && attr !== false && attr > 0) {
            $(this).attr("tabindex", 0);
        }
    });
    let focusable = document.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
    if (focusable.length == 0) return;
    focusable[0].focus();
};
