//RadEditor
function editorsSetup() {

    $(".rtRECView").click(function () {
        $(this).parent().parent().find(".rtRECEdit").show();
        $(this).parent().parent().find(".rtRECView").hide();
        $(this).parent().find(".rtRECEditbtn").hide();
        $(this).parent().find(".rtRECViewbtn").show();
        $find($(this).parent().find(".RadEditor").attr('id')).setFocus();
        moveCaretToEndRadEditor($find($(this).parent().find(".RadEditor").attr('id')));
    });

    $(".rtRECEditbtn").click(function () {
        $(this).parent().parent().find(".rtRECEdit").show();
        $(this).parent().parent().find(".rtRECView").hide();
        $(this).parent().find(".rtRECEditbtn").hide();
        $(this).parent().find(".rtRECViewbtn").show();
        $find($(this).parent().parent().find(".RadEditor").attr('id')).setFocus();
        moveCaretToEndRadEditor($find($(this).parent().parent().find(".RadEditor").attr('id')));
    });

    $(".rtRECViewbtn").click(function () {
        $(this).parent().parent().find(".rtRECEdit").hide();
        $(this).parent().parent().find(".rtRECView").show();
        $(this).parent().find(".rtRECEditbtn").show();
        $(this).parent().find(".rtRECViewbtn").hide();
        copyRadEditorContent($find($(this).parent().parent().find(".RadEditor").attr('id')), $(this).parent().parent().find(".rtRECView"));
    });

    //TextArea
    $(".rtTACView").click(function () {
        $(this).parent().parent().find(".rtTACEdit").show();
        $(this).parent().parent().find(".rtTACView").hide();
        $(this).parent().find(".rtTACEditbtn").hide();
        $(this).parent().find(".rtTACViewbtn").show();
        $(this).parent().parent().find("textarea").focus();
        moveCaretToEnd($(this).parent().parent().find("textarea"));
    });

    $(".rtTACEditbtn").click(function () {
        $(this).parent().parent().find(".rtTACEdit").show();
        $(this).parent().parent().find(".rtTACView").hide();
        $(this).parent().find(".rtTACEditbtn").hide();
        $(this).parent().find(".rtTACViewbtn").show();
        $(this).parent().parent().find("textarea").focus();
        moveCaretToEnd($(this).parent().parent().find("textarea"));
    });

    $(".rtTACViewbtn").click(function () {
        $(this).parent().parent().find(".rtTACEdit").hide();
        $(this).parent().parent().find(".rtTACView").show();
        $(this).parent().find(".rtTACEditbtn").show();
        $(this).parent().find(".rtTACViewbtn").hide();
        $(this).parent().parent().find(".rtTACView").text($(this).parent().parent().find("textarea").val().replace(/\n/gi, "<br />").replace(/\r/, ""));
        $(this).parent().parent().find(".rtTACView").html($(this).parent().parent().find(".rtTACView").html().replace(/&lt;br \/&gt;/gi, "<br />"));
    });

    //Init
    initTextAreas();
    initRadEditors();
}

function ToggleTACEditView(target) {
    var btnView = target.parent().parent().find(".rtTACViewbtn");
    var btnEdit = target.parent().parent().find(".rtTACEditbtn");
    if (btnView.is(':visible')) { btnView.click() }
    else { btnEdit.click() }
}

function ToggleRECEditView(target) {
    var btnView = target.parent().parent().find(".rtRECViewbtn");
    var btnEdit = target.parent().parent().find(".rtRECEditbtn");
    if (btnView.is(':visible')) { btnView.click() }
    else { btnEdit.click() }
}


//RadEditor
function initRadEditors() {
    FixRECs();
}

//function onLoadRadEditors() {
//    updateContent();
//}

function FixRECs() {
    $(".rtRECViewbtn").each(function () {
        $(this).parent().parent().find(".rtRECEdit").hide();
        $(this).parent().parent().find(".rtRECView").show();
        $(this).parent().find(".rtRECEditbtn").show();
        $(this).parent().find(".rtRECViewbtn").hide();
    });
}

//function updateContent() {
//    $(".rtRECViewbtn").each(function () {
//         copyRadEditorContent($find($(this).parent().parent().find(".RadEditor").attr('id')), $(this).parent().parent().find(".rtRECView"));
//    });
//}

function rtRECHideEditor(editor, args) {
    copyRadEditorContent(editor, editorFindPart(editor));
    editorFindPart(editor, "rtRECEdit").hide();
    var style = editor.get_contentArea().style;
    style.margin = "0";
    style.padding = "8px 10px";
    style.border = "0";
}

function editorFindPart(editor, part) {
    return $("#" + editor.get_id()).parent().parent().find("." + part);
}

function moveCaretToEndRadEditor(editor) {
    //editor.set_html('<p>0123456789abcdefghijklmnop</p><br />');
    //editor.set_html('<p>0123456789abcdefghijklmnop</p>');
    //editor.set_html('0123456789abcdefghijklmnop');
    var content = editor.get_html();
    content = FixPTags(content);
    editor.set_html(content);
    editor.repaint();

    try {
        var selectionStart = content.lastIndexOf("</p>");
        if (selectionStart == -1) { selectionStart = content.length; }
        var selectionEnd = selectionStart;

        var iframe = document.getElementById(editor.get_id() + "_contentIframe");
        var iframewin = iframe.contentWindow;
        var input = (iframe.contentDocument || iframe.contentWindow.document).body;
        if (input.setSelectionRange) {
            selectionStart = adjustOffset(input, selectionStart);
            selectionEnd = adjustOffset(input, selectionEnd);
            input.setSelectionRange(selectionStart, selectionEnd);
        }
        else if (input.selection) {
            var range = input.createRange();
            range.collapse(true);
            range.moveEnd('character', selectionEnd);
            range.moveStart('character', selectionStart);
            range.select();
        }
        else if (input.createTextRange) {
            var range = input.createTextRange();
            range.collapse(true);
            range.moveEnd('character', selectionEnd);
            range.moveStart('character', selectionStart);
            range.select();
        }
        else if (editor.get_document().selection) {
            var range = (editor.get_document().selection.createRange());
            var oRange = range.duplicate();
            range.moveEnd('character', selectionEnd);
            range.moveStart('character', selectionStart);
            range.select();
        }
        else if (iframewin.getSelection && isChrome) {//Chrome
            var sel = iframewin.getSelection();
            var range = editor.get_document().createRange();
            var node = range.startContainer;
            var offset = 1;
            node = node.lastChild;
            node = node.lastChild;
            if (node.nodeType != 3) {
                if (node.lastChild && node.lastChild.localName == "p") {
                    node = node.lastChild;
                }
                else {
                    var tempnode = node.lastChild;
                    while (tempnode.previousSibling) {
                        tempnode = tempnode.previousSibling;
                        if (tempnode.localName == "p" || tempnode.nodeType == 3) {
                            node = tempnode;
                            break;
                        }
                    }
                }

            }
            if (node.lastChild) { node = node.lastChild; }
            if (node.nodeType == 3) { offset = node.nodeValue.length; }
            range.setStart(node, offset);
            range.setEnd(node, offset);
            range.collapse(false);
            sel.removeAllRanges();
            sel.addRange(range);
        }
        else if (iframewin.getSelection && !isChrome) {//firefox
            var range = iframewin.getSelection().getRangeAt(0);
            var node = range.startContainer;
            var offset = 1;
            if (node.nodeType != 3) {
                if (node.lastChild && node.lastChild.localName == "p") {
                    node = node.lastChild;
                }
                else {
                    var tempnode = node.lastChild;
                    while (tempnode.previousSibling) {
                        tempnode = tempnode.previousSibling;
                        if (tempnode.localName == "p" || tempnode.nodeType == 3) {
                            node = tempnode;
                            break;
                        }
                    }
                }
            }
            if (node.nodeType == 3) { offset = node.nodeValue.length; }
            range.setEnd(node, offset);
            range.setStart(node, offset);
            range.collapse(false);
        }
        else if (editor.getSelection) {
            var range = editor.getSelection().getRange();
            range.collapse(true);
            range.moveEnd('character', selectionEnd);
            range.moveStart('character', selectionStart);
            range.select();
        }

    }
    catch (err) {
        console.log("Needs looking at oneday!");
        console.log(err);
    }
}

function copyRadEditorContent(src) {
    var dest = $("#" + src.get_id() + "_contentIframeViewOnly");
    setIframeDocument(document.getElementById(src.get_id() + "_contentIframeViewOnly"), src.get_html());
    if ($(window).width() < 1024) {
        dest.contents().find('body').css("min-height", "26px");
    }
    else {
        dest.contents().find('body').css("min-height", "20px");
    }
    dest.height("0px");
    dest.contents().find('body').css("padding", "0");
    dest.contents().find('body').css("margin", "0");
    dest.contents().find('#iFrameHtml').css("padding", "8px 10px");
    dest.contents().find('#iFrameHtml').css("margin", "0");
    dest.contents().find('#iFrameHtml').css("cursor", "pointer");
    dest.contents().find('#iFrameHtml').click(function () { dest.click() });
    dest.height(dest.contents().find('body').height() - 2);
}

function setIframeDocument(iframe, content) {
    if (iframe) {
        content = FixPTags(content);
        var iframeWin = iframe.contentWindow || iframe.contentDocument.parentWindow;
        var d = iframeWin.document;
        d.open();
        d.write('<html id="iFrameHtml"><link href="/css/pmcontent.min.css" rel="stylesheet" /><\/head><body>' + content + '<\/body><\/html>');
        d.close();
    }
};

function FixPTags(content) {

    while (content.endsWith("<br>")) { content = content.substring(0, content.length - 4); }
    while (content.endsWith("<br />")) { content = content.substring(0, content.length - 6); }

    //    content = content.replace(/<p(>| [^>]*>)&nbsp;/gmi, "<p$1"); //remove non breaking spaces
    //    content = content.replace(/<p(>| [^>]*>)[\s\t\n\r]*/gmi, "<p$1"); //remove white space
    //    content = content.replace(/[\s\t\n\r]*<\/p>/gmi, "</p>"); //remove white space
    //    content = content.replace(/<\/p>[\s\t\n\r]*<p(>| [^>]*>)/gmi, "</p><p$1"); //remove white space between end and start p tags

    //    content = content.replace(/<\/p>/gmi, "<p>"); //remove any end p tags
    //    content = content.replace(/<p(>| [^>]*>)/gmi, "</p><p$1"); //add end p tags before all start p tags

    //    content = content.replace(/^\s*<p(>| [^>]*>)/gmi, "<p$1"); //remove white space before starting p tag
    //    content = content.replace(/<\/p>\s*$/gmi, "</p>"); //remove white space after ending p tag

    //    if (!content.startsWith("<p>") && !content.startsWith("<p ")) { content = "<p>" + content; } //add missing start p tag
    //    if (!content.endsWith("</p>")) { content = content + "</p>"; } //add missing end p tag

    //    content = content.replace(/<p(>| [^>]*>)[\s\t\n\r]*/gmi, "<p$1"); //remove white space
    //    content = content.replace(/[\s\t\n\r]*<\/p>/gmi, "</p>"); //remove white space

    //    content = content.replace(/<\/p><p><\/p><p(>| [^>]*>)/gmi, "</p><p$1"); //remove unwanted empty p tags
    //    content = content.replace(/^<p><\/p><p(>| [^>]*>)/gmi, "<p$1"); //remove unwanted empty p tags at start
    //    content = content.replace(/<\/p><p><\/p>$/gmi, "</p>"); //remove unwanted empty p tags at end

    //    content = content.replace(/<p(>| [^>]*>)<\/p>/gmi, "<p$1&nbsp;</p>"); //fix empty p tags

    return content;
}

//function editorOnClientLoad(editor, args) {
//    alert(editor.get_html());
//    editor.add_submit(function () {
//        editor.fire("SelectAll");
//        editor.fire("FormatStripper", { value: "CSS" });
//        alert(editor.get_html());
//    });
//}
